











































































































import Vue from "vue";
import { LoginResponseModel } from "@/models/interface/auth.interface";
import { authService } from "@/services/auth.service";
import { settingsServices } from "@/services/settings.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { debounceProcess } from "@/helpers/debounce";
export default Vue.extend({
  name: "Login",
  data() {
    this.handleSearchCompany = debounceProcess(this.handleSearchCompany, 200);
    return {
      form: this.$form.createForm(this, { name: "signinForm" }),
      dataListCompany: [] as any[],
      formRules: {
        username: {
          label: this.$t("lbl_signin_username"),
          name: "username",
          placeholder: this.$t("lbl_signin_username_placeholder"),
          decorator: [
            "username",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        company: {
          label: this.$t("lbl_signin_company"),
          name: "company",
          placeholder: this.$t("lbl_signin_company_placeholder"),
          decorator: [
            "company",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        password: {
          label: this.$t("lbl_signin_password"),
          name: "password",
          placeholder: this.$t("lbl_signin_password_placeholder"),
          decorator: [
            "password",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      isFormSubmitted: false as boolean,
      loading: {
        company: false,
      }
    };
  },
  computed: {
    dynamicBackground() {
      return process.env.NODE_ENV === "production"
        ? "background-image-prod"
        : process.env.NODE_ENV === "staging"
        ? "background-image-sta"
        : "background-image-dev";
    },
  },
  mounted() {
    this.getListOfCompany("");
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    getListOfCompany(value: string): void {
      let params = {} as RequestQueryParamsModel;
      if (value) params.search = `name~*${value}`;
      this.loading.company = true;
      settingsServices
        .listOfCompany(params, "/login/list")
        .then((res: any) => {
          res.forEach((dataObject, index) => (dataObject.key = index));
          this.dataListCompany = res;
        })
        .finally(() => (this.loading.company = false));
    },
    submitForm(e: Event): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (err || this.isFormSubmitted) return;
        this.isFormSubmitted = true;
        values["username"] = values.username + "." + values.company;
        values["client_id"] = process.env.VUE_APP_CLIENT_ID;
        values["client_secret"] = process.env.VUE_APP_CLIENT_SECRET;
        values["grant_type"] = "password";
        authService
          .signin(values)
          .then((res: LoginResponseModel) => {
            this.$store.dispatch("ACTIONS_POST_LOGIN", res);
            this.$message.success(
              this.$t("notif_login_successfull").toString()
            );
            // Set User Info;
            this.isFormSubmitted = false;
          })
          .catch(() => (this.isFormSubmitted = false));
      });
    },
  },
});
