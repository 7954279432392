var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    { staticClass: "backgroundLayout", class: _vm.dynamicBackground },
    [
      _c(
        "a-row",
        {
          staticStyle: { height: "100vh" },
          attrs: { type: "flex", justify: "center" }
        },
        [
          _c(
            "a-col",
            { staticClass: "loginContainer" },
            [
              _c(
                "a-card",
                { staticClass: "backgroundCard" },
                [
                  _c("a-row", { staticStyle: { "margin-bottom": "30px" } }, [
                    _c("div", { staticClass: "custom-card-title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_signin_welcome")) + " ")
                    ]),
                    _c("div", { staticClass: "custom-card-description" }, [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_signin_desc")) + " ")
                    ])
                  ]),
                  _c(
                    "a-form",
                    {
                      attrs: { form: _vm.form, layout: "vertical" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submitForm.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.formRules.company.label } },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.company.decorator,
                                  expression: "formRules.company.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.company.name,
                                placeholder: _vm.formRules.company.placeholder,
                                "show-search": true,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption,
                                loading: _vm.loading.company,
                                "allow-clear": true
                              },
                              on: {
                                search: function(value) {
                                  return _vm.getListOfCompany(value)
                                }
                              }
                            },
                            _vm._l(_vm.dataListCompany, function(item, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: item.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(" " + _vm._s(item.name) + " ")
                                      ]),
                                      _vm._v(" " + _vm._s(item.name) + " ")
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.formRules.username.label } },
                        [
                          _c(
                            "a-input",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.username.decorator,
                                  expression: "formRules.username.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.username.name,
                                placeholder: _vm.formRules.username.placeholder,
                                autocomplete: "off"
                              }
                            },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "user" },
                                slot: "prefix"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: _vm.formRules.password.label } },
                        [
                          _c(
                            "a-input-password",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.password.decorator,
                                  expression: "formRules.password.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.password.name,
                                placeholder: _vm.formRules.password.placeholder,
                                autocomplete: "false"
                              }
                            },
                            [
                              _c("a-icon", {
                                staticStyle: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "lock" },
                                slot: "prefix"
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.isFormSubmitted,
                            block: "",
                            icon: "login"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_signin")) + " ")]
                      ),
                      _c(
                        "label",
                        { staticClass: "bottomCard" },
                        [
                          _c("i18n", {
                            attrs: { path: "lbl_forgot_password" },
                            scopedSlots: _vm._u([
                              {
                                key: "route",
                                fn: function() {
                                  return [
                                    _c(
                                      "router-link",
                                      {
                                        staticClass: "forgotPassword",
                                        attrs: { to: "./forgot-password" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("lbl_forgot_password_here")
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }