// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/image/wave.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/image/wave-sta.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/image/wave-dev.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".custom-card-title[data-v-5df3655f] {\n  font-weight: bold;\n  font-size: 2.5rem;\n}\n.custom-card-description[data-v-5df3655f] {\n  font-size: 1rem;\n}\n.loginContainer[data-v-5df3655f] {\n  align-self: center;\n  width: 600px;\n}\n.backgroundLayout[data-v-5df3655f] {\n  background-color: #ffffff;\n  background-attachment: fixed;\n  background-position: bottom;\n  background-repeat: no-repeat;\n}\n.bg-prod[data-v-5df3655f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.bg-sta[data-v-5df3655f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.bg-dev[data-v-5df3655f] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.backgroundCard[data-v-5df3655f] {\n  border-radius: 5px;\n}\n.welcomeBack[data-v-5df3655f] {\n  color: black;\n  font-weight: 500;\n  font-size: 34px;\n  -webkit-text-decoration: none solid #ffffff;\n          text-decoration: none solid #ffffff;\n}\n.bottomCard[data-v-5df3655f] {\n  margin-top: 15px;\n}\n/* max <= */\n/* min >= */\n@media screen and (max-width: 500px) {\n.custom-card-title[data-v-5df3655f] {\n    font-size: 2rem;\n}\n.custom-card-description[data-v-5df3655f] {\n    font-size: 0.8rem;\n}\n.loginContainer[data-v-5df3655f] {\n    width: 100%;\n}\n.backgroundCard[data-v-5df3655f] {\n    opacity: 1;\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    left: 0;\n    border-radius: 30px 30px 0 0;\n}\n.backgroundLayout[data-v-5df3655f] {\n    background-color: #ee5522;\n    background-attachment: fixed;\n    background-size: cover;\n}\n}\n@media screen and (max-height: 1000px) and (max-width: 500px) {\n.bottomCard[data-v-5df3655f] {\n    margin-bottom: 200px;\n}\n}\n@media screen and (max-height: 900px) and (max-width: 500px) {\n.bottomCard[data-v-5df3655f] {\n    margin-bottom: 100px;\n}\n}\n@media screen and (max-height: 670px) and (max-width: 500px) {\n.bottomCard[data-v-5df3655f] {\n    margin-bottom: 10px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
